import { gql } from '@apollo/client/core';

export const ALL_PRECEPTORS_PUBLIC = gql`
  query allPreceptorProfilesPublic($first: Int, $skip: Int, $specialty: [Int], $credentials: [Int], $costMin: Int, $costMax: Int, $location: [String], $compactStates: [String], $dateFrom: Date, $dateTo: Date, $hours: Int, $preceptorUid: String, $o: String) {
    allPreceptorProfilesPublic(first: $first, skip: $skip, specialty: $specialty, credentials: $credentials, costMin: $costMin, costMax: $costMax, location: $location, compactStates: $compactStates, dateFrom: $dateFrom, dateTo: $dateTo, hours: $hours, preceptorUid: $preceptorUid, o: $o, useMap: true) {
      totalCount
      preceptorWithUidDoesNotExist
      results {
        account{
          uuid
          uid
          location {
            generalLocation
          }
        }
        credentials {
          key
          value
        }
        typeOfPractice {
          key
          value
        }
        specialty {
          key
          value
        }
        hoursCapPerRotation
        averageNumberOfPatientsPerDay4755 {
          key
          value
        }
        noOfStudentsPrecepted {
          key
          value
        }
        whatIsTheBreakdownForAges9070
        availability
        isPeriodicalAvailable
      }
      locations {
        states {
          point {
            lat
            lng
          }
          count
        }
        counties {
          point {
            lat
            lng
          }
          count
        }
      }
    }
  }
`;

export const ALL_PRECEPTORS = gql`
  query allPreceptorProfilesPublic($first: Int, $skip: Int, $specialty: [Int], $credentials: [Int], $costMin: Int, $costMax: Int, $location: [String], $compactStates: [String], $dateFrom: Date, $dateTo: Date, $hours: Int, $o: String, $costForRotation: String) {
    allPreceptorProfilesPublic(first: $first, skip: $skip, specialty: $specialty, credentials: $credentials, costMin: $costMin, costMax: $costMax, location: $location, compactStates: $compactStates, dateFrom: $dateFrom, dateTo: $dateTo, hours: $hours, o: $o, costForRotation: $costForRotation) {
      totalCount
      results {
        account{
          uuid
          uid
          location {
            generalLocation
          }
          favorites {
            rotation {
              uuid
            }
          }
        }
        credentials {
          key
          value
        }
        typeOfPractice {
          key
          value
        }
        specialty {
          key
          value
        }
        averageNumberOfPatientsPerDay4755 {
          key
          value
        }
        noOfStudentsPrecepted {
          key
          value
        }
        whatIsTheBreakdownForAges9070
        availability
        isPeriodicalAvailable
        hoursCapPerRotation
        costForRotation
      }
    }
  }
`;

const PRECEPTOR_PROFILE_FIELDS = gql`
  fragment PreceptorProfileFields on FavoritePreceptorProfileType {
    account {
      uuid
      uid
      location {
        generalLocation
      }
    }
    credentials {
      key
      value
    }
    typeOfPractice {
      key
      value
    }
    specialty {
      key
      value
    }
    averageNumberOfPatientsPerDay4755 {
      key
      value
    }
    hoursCapPerRotation
    noOfStudentsPrecepted {
      key
      value
    }
    whatIsTheBreakdownForAges9070
    availability
    costForRotation
  }
`;

export const ALL_FAVORITE_PRECEPTORS = gql`
  query allFavoritePreceptorProfiles {
    allFavoritePreceptorProfiles {
      rotation {
        uid
        uuid
        startDate
        endDate
        hours
        paperworkDueDate
        status {
          key
          value
        }
        specialty {
          key
          value
        }
      }
      ...PreceptorProfileFields
    }
  }
  ${PRECEPTOR_PROFILE_FIELDS}
`;

export const FAVORITE_PRECEPTORS = gql`
  query favoritePreceptorProfiles($uuid: String!, $dateFrom: Date, $dateTo: Date, $hours: Int) {
    favoritePreceptorProfiles(uuid: $uuid, dateFrom: $dateFrom, dateTo: $dateTo, hours: $hours) {
      ...PreceptorProfileFields
    }
  }
  ${PRECEPTOR_PROFILE_FIELDS}
`;

export const CANCELED_PRECEPTORS = gql`
  query canceledPreceptorProfiles($uuid: String!) {
    canceledPreceptorProfiles(uuid: $uuid) {
      account {
        id
        uuid
        uid
        location {
          generalLocation
        }
        favorites {
          rotation {
            uuid
          }
        }
      }
      credentials {
        key
        value
      }
      typeOfPractice {
        key
        value
      }
      specialty {
        key
        value
      }
      averageNumberOfPatientsPerDay4755 {
        key
        value
      }
      noOfStudentsPrecepted {
        key
        value
      }
      whatIsTheBreakdownForAges9070
    }
  }
`;

export const PRECEPTOR_PUBLIC = gql`
  query PreceptorPublic($uuid: String!, $dateFrom: Date, $dateTo: Date, $hours: Int) {
    preceptorPublic(uuid: $uuid, dateFrom: $dateFrom, dateTo: $dateTo, hours: $hours) {
      id
      uid
      availability
      availSettings {
        weekdays
        days {
          weekday {
            key
            value
          }
          start {
            key
            value
          }
          end {
            key
            value
          }
          hours
        }
      }
      availRanges {
        uuid
        start
        end
        students
      }
      availDates {
        date
      }
      honorariumHourRanges {
        uuid
        start
        end
        amount
      }
      profile {
        isPeriodicalAvailable
        credentials {
          key
          value
        }
        specialty {
          key
          value
        }
        typeOfPractice {
          key
          value
        }
        timezone {
          key
          value
          options {
            key
            value
          }
        }
        clinicBusinessHours
        hoursCapPerRotation
        preceptorIntro
        limitationsOrRestrictionsForStudents
        whatIsTheDressCodeForStudents {
          key
          value
        }
        vaccinationcovidRequirements {
          key
          value
        }
        whatWillTheStudentExperienceLookLikeAtYourSite
        specialtyNote
        ifYouDoWomensHealthWillYouPreceptAMaleStudentWhoNeedsWomensHealth
        ifYouSeePsychPatientsDoYouDo {
          key
          value
        }
        averageNumberOfPatientsPerDay4755 {
          key
          value
        }
        noOfStudentsPrecepted {
          key
          value
        }
        whatIsTheBreakdownForAges9070
        dayshoursYouWork
        checklist
        honorariumType {
          key
          value
        }
        amount
        fullAmount
        minimumHonorarium
        flatFeeForAllHours
      }
      location {
        generalLocation
      }
    }
  }
`;
